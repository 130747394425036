<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Newsletter Abonnenten</strong>
              <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px" style="float: right"/>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CDataTable
                :items="tableData"
                :itemsPerPageSelect="{ label: 'Abonnenten pro Seite' }"
                :fields="getTableFields()"
                :noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar',
                noItems: 'Es gibt noch keine Abonnenten ' }"
                hover
                striped
                bordered
                fixed
                column-filter
                table-filter
                :tableFilter="{
                label: 'Abonnenten suchen',
                placeholder: 'Suchbegriff eingeben',
                }"
                items-per-page-select
                sorter
                :sorterValue="{ column: 'created', asc: false }"
                pagination
                :loading="tableShowLoading"
            >
              <template #verified="{item}">
                <td>
                  <CBadge :color="item.verified ? 'success' : 'danger'">
                    {{item.verified ? 'Verifiziert' : 'Nicht verifiziert'}}
                  </CBadge>
                </td>
              </template>
              <template #groups="{ item }">
                <td>
                  <CBadge v-for="groupName in item.groups.map(g => g.name)" :key="groupName" color="info" shape="pill" style="margin-right: 4px;">
                    {{ groupName }}
                  </CBadge>
                </td>
              </template>
              <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                      :to="'subscriber/edit/' + item.id"
                      color="primary"
                      variant="outline"
                      class="action-button"
                      square
                      size="sm"
                  >
                    Bearbeiten
                  </CButton>
                  <CButton
                      @click="deleteNewsletterSubscriber(item, index)"
                      color="danger"
                      variant="outline"
                      class="action-button"
                      square
                      size="sm"
                  >
                    <CIcon name="cil-trash"/>
                  </CButton>
                </td>
              </template>
              <template #created="{ item }">
                <td>
                  {{ $moment(item.created).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import commons from '@/commons'
import axios from 'axios'
export default {
  name: 'NewsletterSubscriber',
  components: {
    DoubleBounceLoader
  },
  data () {
    return {
      loading: false,
      saving: false,
      tableData: [],
      tableShowLoading: true
    }
  },
  mounted () {
    this.loading = true
    this.loadNewsletterSubscriber()
  },
  methods: {
    loadNewsletterSubscriber() {
      axios.get('/newsletter/subscriber')
          .then(response => {
            let content = []
            response.data.forEach(value => {
              let toAdd = commons.flatObject(value)
              toAdd.groups = value.groups
              content.push(toAdd)
            })
            this.tableData = content
            this.loading = false
          })
          .catch(err => console.error('Problem while fetching newsletter subscribers', err))
          .finally(() => this.tableShowLoading = false)
    },
    getTableFields() {
      return [
        {key: 'email', label: 'E-Mail'},
        {key: 'firstName', label: 'Vorname'},
        {key: 'lastName', label: 'Nachname'},
        {key: 'groups', label: 'Zielgruppe'},
        {key: 'verified', label: 'Verifiziert?'},
        {key: 'created', label: 'Datum'},
        {
          key: 'show_details',
          label: '',
          _style: 'width:10%',
          sorter: false,
          filter: false
        }
      ]
    },
    deleteNewsletterSubscriber(item, index) {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>Abonnent</b> (${item['email']})`],
          async () => {
              try {
                await axios.delete('/newsletter/subscriber/' + item.id);
                this.$toaster.makeToast('Abonnent gelöscht', `Ihr Newsletter Abonnent "${item.email}" wurde erfolgreich gelöscht!`)
                this.loadNewsletterSubscriber()
              } catch (err) {
                this.$toaster.makeToast('Fehler', `Beim Löschen des Newsletter-Abonnenten "${item.email}" ist ein Fehler aufgetreten. Bitte versuchen Sie es in einem späteren Zeitpunkt erneut!`)
                console.log(err)
              }
          }
      )
    }
  }
}
</script>
